<template lang="pug">
.progress(v-if="maxLimit" :style="{ height: height }")
  template(v-if="!isOverrun")
    .progress-bar(
      role="progressbar"
      :style="{ width: width, 'background-color': '#6D9AE7' }"
      :aria-valuenow="usedLimit"
      aria-valuemin="0"
      :aria-valuemax="maxLimit"
    )
  template(v-else)
    .current.progress-bar(
      role="progressbar"
      :style="{ width: `${calculateCurrentOverRun.value}%`, 'background-color': '#6D9AE7' }"
      :aria-valuenow="usedLimit"
      aria-valuemin="0"
      :aria-valuemax="maxLimit"
    )
    .overrun.progress-bar(
      v-if="isOverrunRunning"
      role="progressbar"
      :style="{ width: `${calculateCurrentOverRun.rest}%` }"
      :aria-valuenow="usedLimit"
      aria-valuemin="0"
      :aria-valuemax="maxLimit"
    )
</template>
<script>
  import { createNamespacedHelpers } from 'vuex';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    props: {
      height: {
        type: String,
        default: '0.375rem',
      },
      width: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...paymentGetters([
        'isOverrun',
        'isOverrunRunning',
        'calculateCurrentOverRun',
        'usedLimit',
        'maxLimit',
      ]),
    },
  };
</script>
<style lang="sass" scoped>
  .progress
    .current
      border-top-right-radius: unset !important
      border-bottom-right-radius: unset !important
    .overrun
      border-top-left-radius: unset !important
      border-bottom-left-radius: unset !important
    .progress-bar
      border-radius: 4px
</style>
